import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

// Constants for axios headers
const AXIOS_LANGUAGE = "Accept-Language";
const AXIOS_AUTH = "Authorization";
export const uploadImageApi = async (formData) => {
    return await axios.post(
      apiUrl + "/franchise/store/menu-extractor",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
export const importDataIntoDB = async (menuItems, overwriteStore) => {
  return await axios.post(
    apiUrl + "/franchise/store/import-data",
    {
      menu: menuItems,
      overwriteStore: overwriteStore,
      scrapedStoreId: localStorage.getItem("storeId") || "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const loginAPI = async (email, password) => {
  // Use the exact endpoint format from the curl request
  return await axios.post(apiUrl + "/franchise/logIn", {
    email,
    password,
    sub: "scraperInternJWT",
    exp: "1d",
  }, {
    headers: {
      "accept": "application/json, text/plain, */*",
      "accept-language": "en-NL,en;q=0.9,nl-NL;q=0.8,nl;q=0.7,en-GB;q=0.6,en-US;q=0.5",
      "content-type": "application/json",
      "language": localStorage.getItem("APP_LANG") || 'en',
      "origin": window.location.origin,
      "referer": window.location.origin + '/'
    }
  });
};

export const loginWithStoreId = (storeId, superToken, fcmUserTopic = "dummy") => {
  return new Promise((resolve, reject) => {            
    // Make sure we're using the correct API endpoint
    // The endpoint should match the structure in the curl request
    const headers = {
      "accept": "application/json, text/plain, */*",
      "accept-language": "en-NL,en;q=0.9,nl-NL;q=0.8,nl;q=0.7,en-GB;q=0.6,en-US;q=0.5",
      "content-type": "application/json",
      "language": localStorage.getItem("APP_LANG") || 'en',
      "authorization": 'Bearer ' + superToken,
      "origin": window.location.origin,
      "referer": window.location.origin + '/',
      "x-frame-options": "sameorigin"
    };
    
    // Use the exact endpoint format
    axios.post(`${apiUrl}/franchise/logInWithStoreId`, 
      { storeId: storeId, fcmUserTopic: fcmUserTopic}, 
      { headers: headers }
    )
    .then(response => {                    
      if (response.status === 200) {
        resolve(response.data);                        
      } else {
        reject(response.data);
      }
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const proxyDownloadApi = async (url) => {
  return await axios.post(
    apiUrl + "/franchise/store/proxy-download",
    { url },
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: 'blob', // Important for handling file downloads
    }
  );
};
