import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loginWithStoreId } from "../api/ApiService";
import { toast } from "react-toastify";
import { useImageStore, useProcessStore } from "../Store";
import { useShallow } from "zustand/shallow";
import LoadingView from "../components/LoadingView";
import image from "../assets/icons/meshilogoback.png";

const StoreLoginPage = () => {
  const { storeId, supertoken } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  
  const { removeAllImages } = useImageStore(
    useShallow((state) => ({
      removeAllImages: state.removeAllImages,
    }))
  );
  
  const { setStoreId } = useProcessStore(
    useShallow((state) => ({
      setStoreId: state.setStoreId,
    }))
  );

  useEffect(() => {
    if (!storeId || !supertoken) {
      toast.error("Invalid login parameters");
      navigate("/login");
      return;
    }

    setIsLoading(true);
    loginWithStoreId(storeId, supertoken)
      .then((response) => {
        const token = response?.data?.authToken;
        if (token) {
          // Store the token in localStorage
          localStorage.setItem("token", token);
          
          // Extract storeId from response if available, otherwise use the URL parameter
          const responseStoreId = response?.data?.storeId || response?.data?.store?.id;
          const finalStoreId = responseStoreId || storeId;
          
          // Store store ID in global state
          if (finalStoreId) {
            setStoreId(finalStoreId);
            console.log(`Logged in with store ID: ${finalStoreId}`);
          }
          
          // Clear any existing images
          removeAllImages();
          
          // Redirect to the main dashboard
          setTimeout(() => {
            navigate("/");
          }, 1000); // Small delay to show success message
        } else {
          toast.error(response?.message ?? "Failed to login with store ID");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.error("Login error:", err);
        toast.error(err?.response?.data?.message || "Failed to login with store ID");
        navigate("/login");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [storeId, supertoken, navigate, removeAllImages]);

  return (
    <div className="container mx-auto flex items-center justify-center h-screen w-screen">
      <div className="w-4/5 lg:w-2/5 px-5 rounded shadow-lg">
        <div className="px-6 py-4 text-center">
          <img src={image} alt="Logo" className="w-14 h-14 mx-auto" />
          <div className="font-bold text-2xl mb-2 text-orange-950">Store Login</div>
          {isLoading ? (
            <div className="flex justify-center items-center py-8">
              <svg
                aria-hidden="true"
                className="w-10 h-10 text-gray-200 animate-spin fill-slate-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="ml-3">Logging in with Store ID...</span>
            </div>
          ) : (
            <div className="text-center py-8">Redirecting to dashboard...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreLoginPage;
